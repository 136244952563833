var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"active-2-box"},[_vm._m(0),_c('div',{staticClass:"active-2-info-box"},[_c('el-descriptions',{attrs:{"title":"","column":4}},[_c('el-descriptions-item',{attrs:{"label":"机动车商业保险保障金额"}},[_vm._v(" "+_vm._s(_vm.info.guaranteeAmountA)+" ")]),_c('el-descriptions-item',{attrs:{"label":"参保次数"}},[_vm._v(" "+_vm._s(_vm.info.numA)+" ")]),_c('el-descriptions-item',{attrs:{"label":"保费总金额"}},[_vm._v(" "+_vm._s(_vm.info.premiumAmountA)+" ")]),_c('el-descriptions-item',{attrs:{"label":"保单状态"}},[_vm._v(" "+_vm._s(_vm.info.statuA)+" ")]),_c('el-descriptions-item',{attrs:{"label":"机动车交通事故责任强制保险保障金额"}},[_vm._v(" "+_vm._s(_vm.info.guaranteeAmountB)+" ")]),_c('el-descriptions-item',{attrs:{"label":"参保次数"}},[_vm._v(" "+_vm._s(_vm.info.numB)+" ")]),_c('el-descriptions-item',{attrs:{"label":"保费总金额"}},[_vm._v(" "+_vm._s(_vm.info.premiumAmountB)+" ")]),_c('el-descriptions-item',{attrs:{"label":"保单状态"}},[_vm._v(" "+_vm._s(_vm.info.statuB)+" ")])],1)],1),_vm._m(1),_c('div',{staticClass:"active-2-table-box"},[_c('vxe-table',{ref:"vxeTable",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","show-overflow":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyleFn,"align":"center","height":"300","data":_vm.records,"sort-config":{ sortMethod: _vm.customSortMethod }}},[_c('vxe-table-column',{attrs:{"title":"保单名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.policyTypeStr)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"保单号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.policyNum)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"保障金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" ￥： "+_vm._s(row.guaranteeAmount)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"保费金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" ￥："+_vm._s(row.premiumAmount)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"保险期间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.takeEffectTime)+" ~ "+_vm._s(row.invalidTime)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.status)+" ")]}}])})],1),_c('div',{staticStyle:{"height":"20px"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-icon"}),_c('div',{staticClass:"title-text"},[_vm._v("总体情况")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-icon"}),_c('div',{staticClass:"title-text"},[_vm._v("保单记录")])])}]

export { render, staticRenderFns }