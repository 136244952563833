<template>
    <div class="active-3-box">
        <div class="title-box">
            <div class="title-icon"></div>
            <div class="title-text">总体情况</div>
        </div>
        <div class="active-3-info-box">
            <el-descriptions title="" :column="4">
                <el-descriptions-item label="维修总次数">{{
                    info.allNum || "--"
                }}</el-descriptions-item>
                <el-descriptions-item label="维修总费用">{{
                    info.allCosts || "--"
                }}</el-descriptions-item>
                <el-descriptions-item label="车辆维修次数">{{
                    info.allNumA || "--"
                }}</el-descriptions-item>
                <el-descriptions-item label="车辆维修费用">{{
                    info.allCostsA || "--"
                }}</el-descriptions-item>
                <el-descriptions-item label="小修次数">{{
                    info.allNumB || "--"
                }}</el-descriptions-item>
                <el-descriptions-item label="小修费用">{{
                    info.allCostsB || "--"
                }}</el-descriptions-item>
                <el-descriptions-item label="大修次数">{{
                    info.allNumC || "--"
                }}</el-descriptions-item>
                <el-descriptions-item label="大修费用">{{
                    info.allCostsC || "--"
                }}</el-descriptions-item>
            </el-descriptions>
        </div>
        <div class="title-box">
            <div class="title-icon"></div>
            <div class="title-text">维修记录</div>
        </div>
        <div class="active-3-table-box">
            <vxe-table
                ref="vxeTable"
                border
                resizable
                show-overflow
                :auto-resize="true"
                :header-row-style="headerStyle"
                :row-style="rowStyleFn"
                align="center"
                class="vxe-table-element"
                height="300"
                :data="records"
                :sort-config="{ sortMethod: customSortMethod }"
            >
                <vxe-table-column title="维护日期">
                    <template v-slot="{ row }">
                        {{ row.arrivalTime }}
                    </template>
                </vxe-table-column>
                <vxe-table-column title="里程表示值（km）">
                    <template v-slot="{ row }">
                        {{ row.drivingMileage }}
                    </template>
                </vxe-table-column>
                <vxe-table-column title="维修类别">
                    <template v-slot="{ row }">
                        {{ row.maintenanceTypeStr }}
                    </template>
                </vxe-table-column>
                <vxe-table-column title="维护项目">
                    <template v-slot="{ row }">
                        {{ row.maintenanceProjectStr }}
                    </template>
                </vxe-table-column>
                <vxe-table-column title="维护费用">
                    <template v-slot="{ row }">
                        {{ row.maintenanceCosts }}
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    title="维护单位"
                    show-header-overflow
                    show-overflow="title"
                    show-footer-overflow
                >
                    <template v-slot="{ row }">
                        {{ row.maintenanceUnit }}
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: "",
        },
        op: {
            type: Object,
            default: () => {
                return {
                    row: null,
                    id: "",
                };
            },
        },
        companyId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            ajaxParam: {
                currentPage: 1,
                pageSize: 20,
                companyId: "",
                // direction: "ASC", //排序方式，默认降序 DESC, ASC
                // property: "nextReplaceTime", // 排序字段，默认创建时间
            },
            info: {
                allCosts: "",
                // 维修总费用(元)

                allCostsA: "",
                // 车辆维修费用(元)

                allCostsB: "",
                // 小修费用(元)

                allCostsC: "",
                // 大修费用(元)

                allNum: "",
                // 维修总次数(次)

                allNumA: "",
                // 车辆维修次数(次)

                allNumB: "",
                // 小修次数(次)

                allNumC: "",
            },
            records: [],
            total: 0,
        };
    },
    created() {
        // this.queryList();
    },
    methods: {
        customSortMethod({ sortList }) {
            const { order } = sortList[0];
            if (this.ajaxParam.direction == order.toUpperCase()) {
                return null;
            }
            this.ajaxParam.direction = order == "asc" ? "ASC" : "DESC";
            this.queryList();
        },
        async queryList(startDate = "", endDate = "") {
            // const ajaxParam = JSON.parse(JSON.stringify(this.ajaxParam));
            // delete ajaxParam.companyId;
            const ajaxParam = {
                currentPage: 1,
                pageSize: 20,
                companyId: this.companyId,
                startDate: startDate,
                endDate: endDate,
                // direction: "ASC", //排序方式，默认降序 DESC, ASC
                // property: "nextReplaceTime", // 排序字段，默认创建时间
            };
            {
                let { code, data } = await this.$api.get(
                    `/car/server/escort/web/carMaintenanceRecords/statistic/${this.id}`,
                    {
                        params: ajaxParam,
                    }
                );
                if (code === 200) {
                    data &&
                        Object.keys(this.info).forEach((key) => {
                            this.info[key] = data[key];
                        });
                }
            }
            {
                let { code, data } = await this.$api.get(
                    `/car/server/escort/web/carMaintenanceRecords/statistic/List/${this.id}`,
                    {
                        params: ajaxParam,
                    }
                );
                if (code === 200) {
                    this.records = data;
                    // this.total = ~~data.totalElements;
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.active-3-box {
    border: 1px solid #e9e9e9;
    padding: 32px;
    border-radius: 6px;
    .title-box {
        // height: 50px;
        display: flex;
        align-items: center;

        .title-icon {
            width: 5px;
            height: 16px;
            background: rgb(72, 152, 226);
            margin-right: 10px;
        }
    }

    .active-3-info-box {
        display: flex;
        flex-wrap: wrap;
        padding: 32px 16px;
        .info-item {
            width: 300px;
            margin: 8px 0;
        }
    }
    .active-3-table-box {
        padding: 16px 0;
    }
}
</style>
