var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"active-4-box"},[_vm._m(0),_c('div',{staticClass:"active-4-info-box"},[_c('el-descriptions',{attrs:{"title":"","column":4}},[_c('el-descriptions-item',{attrs:{"label":"发生事故次数"}},[_vm._v(_vm._s(_vm.info.allNum))]),_c('el-descriptions-item',{attrs:{"label":"直接经济损失"}},[_vm._v(_vm._s(_vm.info.allCoasts)+"元")])],1)],1),_vm._m(1),_c('div',{staticClass:"active-4-table-box"},[_c('vxe-table',{ref:"vxeTable",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","show-overflow":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyleFn,"align":"center","height":"300","data":_vm.records,"sort-config":{ sortMethod: _vm.customSortMethod }}},[_c('vxe-table-column',{attrs:{"title":"事故发生日期"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.accidentTime)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"事故发生地点","show-header-overflow":"","show-overflow":"title","show-footer-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.accidentLocation)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"事故性质","show-header-overflow":"","show-overflow":"title","show-footer-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.accidentNature)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"事故原因","show-header-overflow":"","show-overflow":"title","show-footer-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.accidentAccident)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"车辆损坏情况","show-header-overflow":"","show-overflow":"title","show-footer-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.damage)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"直接经济损失(元)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" ￥ "+_vm._s(row.directEconomicLoss)+" ")]}}])})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-icon"}),_c('div',{staticClass:"title-text"},[_vm._v("总体情况")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title-icon"}),_c('div',{staticClass:"title-text"},[_vm._v("车辆事故记录")])])}]

export { render, staticRenderFns }